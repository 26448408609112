// @ts-ignore
import api from '@/helpers/AxiosConfig.ts';
import BaseApi from '@/data/api/BaseApi';
import User from '@/data/entity/User';
import UserDto from '@/data/entity/dto/UserDto';
import ExecutorDto from '@/data/entity/dto/ExecutorDto';

const URL = 'https://sth8xbrh04.execute-api.us-east-2.amazonaws.com/dev/api/users';

class UserAPI extends BaseApi {
    constructor() {
        super();
        this.url = URL;
    }

    GetUsersByLicenseId = async (pLicenseId: number): Promise<User[]> => {
        const response = await api.get<User[]>(`${this.url}/license`, { params: { licenseId: pLicenseId } });
        return (response && response.data) || null;
    };

    GetUsersById = async (pUserId: number): Promise<User[]> => {
        const response = await api.get<User[]>(`${this.url}/user`, { params: { userId: pUserId } });
        return (response && response.data) || null;
    };

    GetLoginUser = async (pEmail: string, pPassword: string): Promise<User[]> => {
        const response = await api.get<User[]>(`${this.url}/user/login`, {
            params: {
                email: pEmail,
                password: pPassword,
            },
        });
        return (response && response.data) || null;
    };

    GetLoginUserCognito = async (user?: UserDto): Promise<any[]> => {
        const response = await api.post<any[]>(`${this.url}/cognito/login`, user);
        return (response && response.data) || null;
    };

    GetLoginFederatedUser = async (data?: any): Promise<any[]> => {
        const response = await api.post<any[]>(`${this.url}/cognito/federated-login`, data);
        return (response && response.data) || null;
    };

    InsertUserCognito = async (user?: User): Promise<any> => {
        const response = await api.post<any>(this.url, user);
        return (response && response.data) || null;
    };

    UpdateUser = async (user?: User): Promise<any> => {
        const response = await api.put<any>(this.url, user);
        return (response && response.data) || null;
    };

    updateAcceptTermsByUser = async (user?: User): Promise<any> => {
        const response = await api.put<any>(`${this.url}/update-user-accept-terms`, user);
        return (response && response.data) || null;
    };

    updateAcceptPersonalDataProtection = async (user?: User): Promise<any> => {
        const response = await api.put<any>(`${this.url}/update-accept-personal-data-protection`, user);
        return (response && response.data) || null;
    };

    PasswordRecovery = async (user?: UserDto): Promise<any> => {
        const response = await api.put<any>(`${this.url}/user/recovery-password`, user);
        return (response && response.data) || null;
    };

    ChangePassword = async (user?: UserDto): Promise<any> => {
        const response = await api.put<any>(`${this.url}/user/change-password`, user);
        return (response && response.data) || null;
    };

    updateNotificationNewLegalRule = async (user?: User): Promise<any> => {
        const response = await api.put<any>(`${this.url}/update-notification-new-legal-rule`, user);
        return (response && response.data) || null;
    };

    updateUserLegalMatters = async (userData?: any): Promise<any> => {
        const response = await api.put<any>(`${this.url}/update-user-legal-matters`, userData);
        return (response && response.data) || null;
    };

    updateLimitLegalMatter = async (user?: User): Promise<any> => {
        const response = await api.put<any>(`${this.url}/update-limit-legal-matter`, user);
        return (response && response.data) || null;
    };

    updateLimitCountry = async (user?: User): Promise<any> => {
        const response = await api.put<any>(`${this.url}/update-limit-country`, user);
        return (response && response.data) || null;
    };

    updateUserCountries = async (userData?: any): Promise<any> => {
        const response = await api.put<any>(`${this.url}/update-user-countries`, userData);
        return (response && response.data) || null;
    };

    updateLimitLicenseAssociate = async (user?: User): Promise<any> => {
        const response = await api.put<any>(`${this.url}/update-limit-license-associate`, user);
        return (response && response.data) || null;
    };

    updateUserLicenseAssociates = async (userData?: any): Promise<any> => {
        const response = await api.put<any>(`${this.url}/update-user-license-associates`, userData);
        return (response && response.data) || null;
    };

    GetTemporaryCredentials = async (): Promise<any[]> => {
        const response = await api.get<any[]>(`${this.url}/user/temporary-credentials`);
        return (response && response.data) || null;
    };

    UpdateNotificationProgrammedLegalRule = async (user?: User): Promise<any> => {
        const response = await api.put<any>(`${this.url}/update-notification-programmed-legal-rule`, user);
        return (response && response.data) || null;
    };

    UpdateProgrammedLegalRule = async (user?: User): Promise<any> => {
        const response = await api.put<any>(`${this.url}/update-programmed-legal-rule`, user);
        return (response && response.data) || null;
    };

    GetUsersByLicenseForList = async (pLicenseId: number): Promise<any[]> => {
        const response = await api.get<ExecutorDto[]>(`${this.url}/get-users-by-license-for-list`,
            {
                params:
                    {
                        licenseId: pLicenseId,
                    },
            });
        return (response && response.data) || null;
    };

}

export default new UserAPI();
