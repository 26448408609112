export default class ERouteType {
  static readonly HOME_PATH: string = '/';

  static readonly HOME_NAME: string = 'Home';

  static readonly LICENSE_PATH: string = '/license';

  static readonly LICENSE_NAME: string = 'License';

  static readonly LICENSE_LIST_PATH: string = '/license-list';

  static readonly LICENSE_LIST_NAME: string = 'LicenseList';

  static readonly LICENSE_EDIT_PATH: string = '/license-edit/:id';

  static readonly LICENSE_EDIT_NAME: string = 'LicenseEdit';

  static readonly USER_PATH: string = '/user/:clientLicenseId';

  static readonly USER_NAME: string = 'User';

  static readonly USER_LIST_PATH: string = '/user-list';

  static readonly USER_LIST_NAME: string = 'UserList';

  static readonly USER_LIST_BY_LICENSE_PATH: string = '/user-list-license/:clientLicenseId';

  static readonly USER_LIST_BY_LICENSE_NAME: string = 'UserListByLicense';

  static readonly USER_EDIT_PATH: string = '/user-edit/:id/:clientLicenseId';

  static readonly USER_EDIT_NAME: string = 'UserEdit';

  static readonly LEGAL_RULE_PATH: string = '/legal-rule';

  static readonly LEGAL_RULE_NAME: string = 'LegalRule';

  static readonly LEGAL_RULE_LIST_PATH: string = '/legal-rule-list';

  static readonly LEGAL_RULE_LIST_NAME: string = 'LegalRuleList';

  static readonly LEGAL_RULE_EDIT_PATH: string = '/legal-rule-edit/:id';

  static readonly LEGAL_RULE_EDIT_NAME: string = 'LegalRuleEdit';

  static readonly ARTICLE_PATH: string = '/article/:legalRuleId';

  static readonly ARTICLE_NAME: string = 'Article';

  static readonly ARTICLE_LIST_PATH: string = '/article-list';

  static readonly ARTICLE_LIST_NAME: string = 'ArticleList';

  static readonly ARTICLE_LIST_BY_LEGAL_RULE_PATH: string = '/article-list/:legalRuleId';

  static readonly ARTICLE_LIST_BY_LEGAL_RULE_NAME: string = 'ArticleListByLegalRule';

  static readonly ARTICLE_EDIT_PATH: string = '/article-edit/:id';

  static readonly ARTICLE_EDIT_NAME: string = 'ArticleEdit';

  static readonly MODIFICATION_LIST_PATH: string = '/modification-list/:articleId/:legalRuleId';

  static readonly MODIFICATION_LIST_NAME: string = 'ModificationList';

  static readonly MODIFICATION_EDIT_PATH: string = '/modification-edit/:id';

  static readonly MODIFICATION_EDIT_NAME: string = 'ModificationEdit';

  static readonly MODIFICATION_PATH: string = '/modification/:articleId/:legalRuleId';

  static readonly MODIFICATION_NAME: string = 'Modification';

  static readonly ERRATA_LIST_PATH: string = '/errata-list/:articleId/:legalRuleId';

  static readonly ERRATA_LIST_NAME: string = 'ErrataList';

  static readonly ERRATA_EDIT_PATH: string = '/errata-edit/:id';

  static readonly ERRATA_EDIT_NAME: string = 'ErrataEdit';

  static readonly ERRATA_PATH: string = '/errata/:articleId/:legalRuleId';

  static readonly ERRATA_NAME: string = 'Errata';

  static readonly ABROGATE_LIST_PATH: string = '/abrogate-list/:articleId/:legalRuleId';

  static readonly ABROGATE_LIST_NAME: string = 'AbrogateList';

  static readonly ABROGATE_EDIT_PATH: string = '/abrogate-edit/:id';

  static readonly ABROGATE_EDIT_NAME: string = 'AbrogateEdit';

  static readonly ABROGATE_PATH: string = '/abrogate/:articleId/:legalRuleId';

  static readonly ABROGATE_NAME: string = 'Abrogate';

  static readonly CONFIGURATIONS_PATH: string = '/configuraciones';

  static readonly CONFIGURATIONS_NAME: string = 'Configuraciones';

  static readonly OPTIONS_LEGAL_RULE_PATH: string = '/options-legal-rule';

  static readonly OPTIONS_LEGAL_RULE_NAME: string = 'OptionsLegalRule';

  static readonly OPTIONS_LEGAL_RULE_TYPE_PATH: string = '/options-legal-rule-type';

  static readonly OPTIONS_LEGAL_RULE_TYPE_NAME: string = 'OptionsLegalRuleType';

  static readonly LEGAL_RULE_LIST_BY_CRITERIA_PATH: string = '/legal-rule-list-by-criteria';

  static readonly LEGAL_RULE_LIST_BY_CRITERIA_NAME: string = 'LegalRuleListByCriteria';

  static readonly ARTICLE_LIST_BY_CRITERIA_PATH: string = '/article-list-by-criteria';

  static readonly ARTICLE_LIST_BY_CRITERIA_NAME: string = 'ArticleListByCriteria';

  static readonly LEGAL_MATRIX_PATH: string = '/legal-matrix';

  static readonly LEGAL_MATRIX_NAME: string = 'LegalMatrix';

  static readonly LEGAL_MATRIX_LIST_PATH: string = '/legal-matrix-list';

  static readonly LEGAL_MATRIX_LIST_NAME: string = 'LegalMatrixList';

  static readonly LEGAL_MATRIX_EDIT_PATH: string = '/legal-matrix/:id';

  static readonly LEGAL_MATRIX_EDIT_NAME: string = 'LegalMatrixEdit';

  static readonly SELECTED_FILTER_PATH: string = '/selected-filter/:legalMatrixId';

  static readonly SELECTED_FILTER_NAME: string = 'SelectedFilter';

  static readonly ARTICLE_LIST_BY_MATRIX_PATH: string = '/article-list-by-matrix/:legalMatrixId/:selectedFilterId';

  static readonly ARTICLE_LIST_BY_MATRIX_NAME: string = 'ArticleListByMatrix';

  static readonly ARTICLES_BY_MATRIX_PATH: string = '/articles-by-matrix/:legalMatrixId/:selectedFilterId';

  static readonly ARTICLES_BY_MATRIX_NAME: string = 'ArticlesByMatrix';

  static readonly EXPORT_MATRIX_TO_EXCEL_PATH: string = '/export-matrix-to-excel/:legalMatrixId/:selectedFilterId';

  static readonly EXPORT_MATRIX_TO_EXCEL_NAME: string = 'ExportMatrixToExcel';

  static readonly EXPORT_MATRIX_TO_EXCEL_BY_EVALUATION_COMPLIANCE_PATH: string = '/export-matrix-to-excel-by-evaluation-compliance/:legalMatrixId/:selectedFilterId';

  static readonly EXPORT_MATRIX_TO_EXCEL_BY_EVALUATION_COMPLIANCE_NAME: string = 'ExportMatrixToExcelByEvaluationCompliance';

  static readonly EVALUATION_PATH: string = '/evaluation/:articleId';

  static readonly EVALUATION_NAME: string = 'Evaluation';

  static readonly EVALUATION_EDIT_PATH: string = '/evaluation/:articleId/:id';

  static readonly EVALUATION_EDIT_NAME: string = 'EvaluationEdit';

  static readonly EVALUATION_LAST_LIST_PATH: string = '/evaluation-last-list';

  static readonly EVALUATION_LAST_LIST_NAME: string = 'EvaluationLastList';

  static readonly ACTION_PLAN_PATH: string = '/action-plan/:articleId/:evaluationId';

  static readonly ACTION_PLAN_NAME: string = 'ActionPlan';

  static readonly ACTION_PLAN_EDIT_PATH: string = '/action-plan/:articleId/:evaluationId/:id';

  static readonly ACTION_PLAN_EDIT_NAME: string = 'ActionPlanEdit';

  static readonly ACTION_PLAN_LIST_PATH: string = '/action-plan-list';

  static readonly ACTION_PLAN_LIST_NAME: string = 'ActionPlanList';

  static readonly LICENSE_BY_USER_PATH: string = '/license-by-user';

  static readonly LICENSE_BY_USER_NAME: string = 'LicenseByUser';

  static readonly DASHBOARD_PATH: string = '/dashboard';

  static readonly DASHBOARD_NAME: string = 'dashboard';

  static readonly IMPORT_LEGAL_RULES_PATH: string = '/import-legal-rules';

  static readonly IMPORT_LEGAL_RULES_NAME: string = 'ImportLegalRules';

  static readonly IMPORT_LEGAL_RULES_SUBSCRIPTION_PATH: string = '/import-legal-rules-subscription';

  static readonly IMPORT_LEGAL_RULES_SUBSCRIPTION_NAME: string = 'ImportLegalRulesSubscription';

  static readonly COMPLIANCE_SUMMARY_BY_CRITERIA_PATH: string = '/compliance-summary-by-criteria';

  static readonly COMPLIANCE_SUMMARY_BY_CRITERIA_NAME: string = 'ComplianceSummaryByCriteria';

  static readonly COMPLIANCE_GENERAL_UNIQUE_PATH: string = '/compliance-general-unique';

  static readonly COMPLIANCE_GENERAL_UNIQUE_NAME: string = 'ComplianceGeneralUnique';

  static readonly COMPLIANCE_GENERAL_MULTIPLE_PATH: string = '/compliance-general-multiple';

  static readonly COMPLIANCE_GENERAL_MULTIPLE_NAME: string = 'ComplianceGeneralMultiple';

  static readonly COMPLIANCE_SUMMARY_BY_CRITERIA_PBI_PATH: string = '/compliance-summary-by-criteria-pbi';

  static readonly COMPLIANCE_SUMMARY_BY_CRITERIA_PBI_NAME: string = 'ComplianceSummaryByCriteriaPbi';

  static readonly COMPLIANCE_GENERAL_PBI_PATH: string = '/compliance-general-pbi';

  static readonly COMPLIANCE_GENERAL_PBI_NAME: string = 'ComplianceGeneralPbi';

  static readonly COMPLIANCE_GENERAL_MULTIPLE_PBI_PATH: string = '/compliance-general-multiple-pbi';

  static readonly COMPLIANCE_GENERAL_MULTIPLE_PBI_NAME: string = 'ComplianceGeneralMultiplePbi';

  /// ///////

  static readonly KEYWORD_PATH: string = '/keyword';

  static readonly KEYWORD_NAME: string = 'Keyword';

  static readonly KEYWORD_LIST_PATH: string = '/keyword-list';

  static readonly KEYWORD_LIST_NAME: string = 'KeywordList';

  static readonly KEYWORD_EDIT_PATH: string = '/Keyword-edit/:id';

  static readonly KEYWORD_EDIT_NAME: string = 'KeywordEdit';
  /// //////

  /// ///////

  static readonly LEGAL_MATTER_PATH: string = '/legal-matter';

  static readonly LEGAL_MATTER_NAME: string = 'LegalMatter';

  static readonly LEGAL_MATTER_LIST_PATH: string = '/legal-matter-list';

  static readonly LEGAL_MATTER_LIST_NAME: string = 'LegalMatterList';

  static readonly LEGAL_MATTER_EDIT_PATH: string = '/legal-matter-edit/:id';

  static readonly LEGAL_MATTER_EDIT_NAME: string = 'LegalMatterEdit';
  /// //////

  /// ///////

  static readonly ACTIVITY_PATH: string = '/activity';

  static readonly ACTIVITY_NAME: string = 'Activity';

  static readonly ACTIVITY_LIST_PATH: string = '/activity-list';

  static readonly ACTIVITY_LIST_NAME: string = 'ActivityList';

  static readonly ACTIVITY_EDIT_PATH: string = '/activity-edit/:id';

  static readonly ACTIVITY_EDIT_NAME: string = 'ActivityEdit';
  /// //////
  /// ///////

  static readonly LEGAL_RULE_TYPE_PATH: string = '/legal-rule-type';

  static readonly LEGAL_RULE_TYPE_NAME: string = 'LegalRuleType';

  static readonly LEGAL_RULE_TYPE_LIST_PATH: string = '/legal-rule-type-list';

  static readonly LEGAL_RULE_TYPE_LIST_NAME: string = 'LegalRuleTypeList';

  static readonly LEGAL_RULE_TYPE_EDIT_PATH: string = '/legal-rule-type-edit/:id';

  static readonly LEGAL_RULE_TYPE_EDIT_NAME: string = 'LegalRuleTypeEdit';
  /// //////

  /// ///////
  static readonly OPTIONS_USERS_PATH: string = '/options-users';

  static readonly OPTIONS_USERS_NAME: string = 'OptionsUsers';
  /// //////

  /// ///////

  static readonly ISSUING_ENTITY_PATH: string = '/issuing-entity';

  static readonly ISSUING_ENTITY_NAME: string = 'IssuingEntity';

  static readonly ISSUING_ENTITY_LIST_PATH: string = '/issuing-entity-list';

  static readonly ISSUING_ENTITY_LIST_NAME: string = 'IssuingEntityList';

  static readonly ISSUING_ENTITY_EDIT_PATH: string = '/issuing-entity-edit/:id';

  static readonly ISSUING_ENTITY_EDIT_NAME: string = 'IssuingEntityEdit';
  /// //////

  static readonly ATTACHED_ENTITY_LIST_PATH: string = '/attached-entity-list';

  static readonly ATTACHED_ENTITY_LIST_NAME: string = 'AttachedEntityList';

  /// ///////

  static readonly THEME_PATH: string = '/theme';

  static readonly THEME_NAME: string = 'Theme';

  static readonly THEME_LIST_PATH: string = '/theme-list';

  static readonly THEME_LIST_NAME: string = 'ThemeList';

  static readonly THEME_EDIT_PATH: string = '/theme-edit/:id';

  static readonly THEME_EDIT_NAME: string = 'ThemeEdit';

  static readonly THEME_BY_LEGAL_RULE_PATH: string = '/theme-by-legal-rule';

  static readonly THEME_BY_LEGAL_RULE_NAME: string = 'ThemeByLegalRule';
  /// //////

  /// ///////

  static readonly EXECUTOR_PATH: string = '/executor';

  static readonly EXECUTOR_NAME: string = 'Executor';

  static readonly EXECUTOR_LIST_PATH: string = '/executor-list';

  static readonly EXECUTOR_LIST_NAME: string = 'ExecutorList';

  static readonly EXECUTOR_EDIT_PATH: string = '/executor-edit/:id';

  static readonly EXECUTOR_EDIT_NAME: string = 'ExecutorEdit';
  /// //////

  static readonly CAMPUS_PROJECT_LIST_PATH: string = '/campus-project-list';

  static readonly CAMPUS_PROJECT_LIST_NAME: string = 'CampusProjectList';

  static readonly SUBSCRIPTION_PATH: string = '/subscription';

  static readonly SUBSCRIPTION_NAME: string = 'Subscription';

  static readonly LEGAL_RULE_SUBSCRIPTION_LIST_PATH: string = '/legal-rule-subscription-list';

  static readonly LEGAL_RULE_SUBSCRIPTION_LIST_NAME: string = 'LegalRuleSubscriptionList';

  static readonly REGULARITY_ENTITY_LIST_PATH: string = '/regularity-entity-list';

  static readonly REGULARITY_ENTITY_LIST_NAME: string = 'RegularityEntityList';
}
