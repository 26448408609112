<template>
    <div :class='containerClass' @click='onDocumentClick'>
        <AppTopBar :topbarMenuActive='topbarMenuActive' :activeTopbarItem='activeTopbarItem'
                   :horizontal='isHorizontal()' :profileMode='profileMode'
                   @menubutton-click='onMenuButtonClick' @topbar-menubutton-click='onTopbarMenuButtonClick'
                   @topbar-item-click='onTopbarItemClick'></AppTopBar>

        <transition name='layout-menu-container'>
            <div class='layout-menu-container' @click='onMenuClick' v-show='isMenuVisible()'>
                <div class='layout-menu-logo' @click="$router.push({path: '/'})">
                    <button class='p-link'>
                        <img id='layout-menu-logo' src='layout/images/logo-h100w.png' alt='babylon-layout' />
                    </button>
                </div>
                <div class='layout-menu-wrapper'>
                    <div class='menu-scroll-content'>
                        <AppInlineProfile v-if='displayInlineProfile' @profile-click='onProfileClick'
                                          :expanded='profileExpanded'></AppInlineProfile>
                        <AppMenu :model='menu' :layoutMode='layoutMode' :active='menuActive'
                                 :mobileMenuActive='staticMenuMobileActive' @menuitem-click='onMenuItemClick'
                                 @root-menuitem-click='onRootMenuItemClick'></AppMenu>
                    </div>
                </div>
            </div>
        </transition>

        <div class='layout-main'>
            <AppBreadcrumb></AppBreadcrumb>

            <div class='layout-content'>
                <router-view />
            </div>

            <AppFooter />
        </div>
        <div v-if='staticMenuMobileActive' class='layout-mask'></div>
    </div>

    <v-idle
        v-show='false'
        v-if='auth["authenticate"]'
        @idle='onIdle'
        :wait='30'
        :duration='1200' />

    <Dialog
        header='Aviso'
        v-model:visible='displayLogoutDialog'
        :breakpoints="{'960px': '75vw', '640px': '90vw'}"
        :style="{width: '350px'}"
        :modal='true'>
        <div class='confirmation-content'>
            <i
                class='pi pi-exclamation-triangle mr-3'
                style='font-size: 2rem' />
            <span>{{ 'Su sesión a caducado por inactividad.' }}</span>
        </div>
        <template #footer>
            <Button
                label='Aceptar'
                icon='pi pi-check'
                @click='closeConfirmation'
                class='p-button-text'
                autofocus />
        </template>
    </Dialog>
</template>

<script setup lang='ts'>
import { inject, ref, computed, watch, reactive } from 'vue';
import ERouteType from '@/router/ERouteType';
import Store from '@/data/Store';
import global from '@/data/global';
import router from '@/router';
import AppTopBar from './AppTopbar.vue';
import AppInlineProfile from './AppInlineProfile.vue';
import AppMenu from './AppMenu.vue';
import AppBreadcrumb from './AppBreadcrumb.vue';
import AppFooter from './AppFooter.vue';
import EventBus from './event-bus';
import { usePrimeVue } from 'primevue/config';
import { useToast } from 'primevue/usetoast';
import Auth from '@/data/Auth';

const authStorage = localStorage.getItem('auth');

const authLocal = reactive(authStorage ? JSON.parse(authStorage) : new Auth(false));

const { license } = authLocal;

const primeVue = usePrimeVue();

const toast = useToast();

const displayLogoutDialog = ref(false);

const layoutMode = ref('static');
const staticMenuDesktopInactive = ref(false);
const staticMenuMobileActive = ref(false);
const overlayMenuActive = ref(false);
const topbarMenuActive = ref(false);
const topbarItemClick = ref(false);
const activeTopbarItem = ref(null);
const menuActive = ref(false);
const menuClick = ref(false);
const darkMenu = ref(false);
const profileMode = ref('popup');
const profileClick = ref(false);
const profileExpanded = ref(false);
const configClick = ref(false);
const configActive = ref(false);
const menu = ref([
    // {
    //     label: 'Home Page', icon: 'pi pi-fw pi-home',
    //     items: [
    //         { label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/dashboard', show: global.isSuperUser() },
    //     ],
    // },
    {
        label: 'Administrar',
        icon: 'pi pi-fw pi-home',
        items: [
            {
                label: 'Licencias',
                icon: 'pi pi-fw pi-bookmark',
                to: ERouteType.LICENSE_LIST_PATH,
                show: global.isSuperUser(),
            },
            {
                label: 'Usuarios',
                icon: 'pi pi-fw pi-users',
                to: ERouteType.USER_LIST_PATH,
                show: global.isAdmin(),
            },
            {
                label: 'Responsables',
                icon: 'pi pi-fw pi-user-edit',
                to: ERouteType.EXECUTOR_LIST_PATH,
                show: global.isAdmin(),
            },
            {
                label: 'Sedes / Proyectos',
                icon: 'pi pi-fw pi-building',
                to: ERouteType.CAMPUS_PROJECT_LIST_PATH,
                show: global.isAdmin(),
            },
            {
                label: 'Entidades Adscritas',
                icon: 'pi pi-fw pi-slack',
                to: ERouteType.ATTACHED_ENTITY_LIST_PATH,
                show: global.isLawyer(),
            },
            {
                label: 'Entidades Emisoras',
                icon: 'pi pi-fw pi-slack',
                to: ERouteType.ISSUING_ENTITY_LIST_PATH,
                show: global.isLawyer(),
            },
            {
                label: 'Entidades Reguladoras',
                icon: 'pi pi-fw pi-slack',
                to: ERouteType.REGULARITY_ENTITY_LIST_PATH,
                show: global.isLawyer(),
            },
            {
                label: 'Tipos de Norma',
                icon: 'pi pi-fw pi-qrcode',
                to: ERouteType.LEGAL_RULE_TYPE_LIST_PATH,
                show: global.isLawyer(),
            },
            {
                label: 'Temas',
                icon: 'pi pi-fw pi-th-large',
                to: ERouteType.THEME_LIST_PATH,
                show: global.isLawyer(),
            },
            {
                label: 'Temas por Norma',
                icon: 'pi pi-fw pi-th-large',
                to: ERouteType.THEME_BY_LEGAL_RULE_PATH,
                show: (global.isAdmin() || global.isLawyer() || global.isLawyerUser() ) && license.activeThemesAndSubThemes ===1,
            },
            {
                label: 'Palabras Clave',
                icon: 'pi pi-fw pi-share-alt',
                to: ERouteType.KEYWORD_LIST_PATH,
                show: global.isLawyer(),
            },
            {
                label: 'Materias',
                icon: 'pi pi-fw pi-clone',
                to: ERouteType.LEGAL_MATTER_LIST_PATH,
                show: global.isLawyer(),
            },
            {
                label: 'Actividades',
                icon: 'pi pi-fw pi-copy',
                to: ERouteType.ACTIVITY_LIST_PATH,
                show: global.isLawyer(),
            },
        ],
    },
    {
        label: 'Gestionar', icon: 'pi pi-fw pi-home',
        items: [
            {
                label: 'Normas',
                icon: 'pi pi-fw pi-book',
                to: ERouteType.LEGAL_RULE_LIST_PATH,
                show: global.isLawyer(),
            },
            {
                label: 'Normas Para Suscritos',
                icon: 'pi pi-fw pi-bookmark',
                to: ERouteType.LEGAL_RULE_SUBSCRIPTION_LIST_PATH,
                show: global.isLawyer(),
            },
            {
                label: 'Normas',
                icon: 'pi pi-fw pi-book',
                to: ERouteType.LEGAL_RULE_LIST_BY_CRITERIA_PATH,
                show: global.isAdmin() || global.isUser() || global.isLawyerUser(),
            },
            // {
            //     label: 'Artículos',
            //     icon: 'pi pi-fw pi-table',
            //     to: ERouteType.ARTICLE_LIST_BY_CRITERIA_PATH,
            //     show: global.isAdmin() || global.isUser(),
            // },
            {
                label: 'Matrices de evaluación',
                icon: 'pi pi-fw pi-tags',
                to: ERouteType.LEGAL_MATRIX_LIST_PATH,
                show: global.isAdmin() || global.isUser(),
            },
            {
                label: 'Ultimas evaluaciones',
                icon: 'pi pi-fw pi-box',
                to: ERouteType.EVALUATION_LAST_LIST_PATH,
                show: global.isAdmin() || global.isUser(),
            },
            // {
            //     label: 'Planes de acción',
            //     icon: 'pi pi-fw pi-share-alt',
            //     to: ERouteType.ACTION_PLAN_LIST_PATH,
            //     show: global.isAdmin() || global.isUser(),
            // },
        ],
    },
    {
        label: 'Reportes', icon: 'pi pi-fw pi-home',
        items: [
            {
                label: 'Cumplimiento de Normas general',
                icon: 'pi pi-fw pi-chart-pie',
                to: ERouteType.COMPLIANCE_SUMMARY_BY_CRITERIA_PATH,
                show: global.isAdmin() || global.isUser(),
            },
            // {
            //     label: 'Cumplimiento de Normas gerencial',
            //     icon: 'pi pi-fw pi-chart-bar',
            //     to: ERouteType.COMPLIANCE_GENERAL_UNIQUE_PATH,
            //     show: global.isAdmin() || global.isUser(),
            // },
            // {
            //     label: 'Cumplimiento de Normas gerencial, múltiples sedes o proyectos',
            //     icon: 'pi pi-fw pi-chart-line',
            //     to: ERouteType.COMPLIANCE_GENERAL_MULTIPLE_PATH,
            //     show: (global.isAdmin() || global.isUser()) && license.matrixByProject===1,
            // },
            // {
            //     label: 'Cumplimiento de Normas BI',
            //     icon: 'pi pi-fw pi-list',
            //     to: ERouteType.COMPLIANCE_SUMMARY_BY_CRITERIA_PBI_PATH,
            //     show: global.isAdmin() || global.isUser(),
            // },
            {
                label: 'Gerencial BI',
                icon: 'pi pi-fw pi-chart-bar',
                to: ERouteType.COMPLIANCE_GENERAL_PBI_PATH,
                show: global.isAdmin() || global.isUser()
            },
            {
                label: 'Gerencial Sede o Proyecto BI',
                icon: 'pi pi-fw pi-chart-line',
                to: ERouteType.COMPLIANCE_GENERAL_MULTIPLE_PBI_PATH,
                show: (global.isAdmin() || global.isUser()) && license.matrixByProject===1,
            },
        ],
    },
    {
        label: '',
        icon: 'pi pi-fw pi-list',
        items: [
            {
                label: 'Mi Licencia',
                icon: 'pi pi-fw pi-id-card',
                to: ERouteType.LICENSE_BY_USER_PATH,
                show: global.isAdmin() || global.isUser() || global.isLawyerUser(),
            },
        ],
    },
    {
        label: '',
        icon: 'pi pi-fw pi-list',
        items: [
            {
                label: 'Cerrar Sesión',
                icon: 'pi pi-fw pi-sign-out',
                command: () => {
                    // eslint-disable-next-line no-use-before-define
                    logOutLocal();
                },
                show: true,
            },
        ],
    }
]);

// region eliminar menus por filtro
const filterMenu = (menuX: any) => {
    menuX.filter((item: any) => {
        if (item.items) {
            item.items = item.items.filter((x: any) => x.show === true);
        }
    });

    const newMenu: any = [];

    menuX.forEach((item: any) => {
        if (item.items && item.items.length > 0) {
            newMenu.push(item);
        }
    });

    menu.value = newMenu;

};

filterMenu(menu.value);
// endregion

const containerClass = computed(() => {
    return ['layout-wrapper', {
        'layout-horizontal': layoutMode.value === 'horizontal',
        'layout-overlay': layoutMode.value === 'overlay',
        'layout-static': layoutMode.value === 'static',
        'layout-slim': layoutMode.value === 'slim',
        'layout-static-inactive': staticMenuDesktopInactive.value,
        'layout-mobile-active': staticMenuMobileActive.value,
        'layout-overlay-active': overlayMenuActive.value,
        'layout-menu-dark': darkMenu.value,
        'layout-menu-light': !darkMenu.value,
        'p-input-filled': primeVue.config.inputStyle === 'filled',
        'p-ripple-disabled': primeVue.config.ripple === false,
    }];
});

const displayInlineProfile = computed(() => {
    return profileMode.value === 'inline' && layoutMode.value !== 'horizontal';
});

const onDocumentClick = () => {
    if (!topbarItemClick.value) {
        activeTopbarItem.value = null;
        topbarMenuActive.value = false;
    }

    if (!menuClick.value) {
        if (isHorizontal() || isSlim()) {
            menuActive.value = false;
        }

        if (overlayMenuActive.value || staticMenuMobileActive.value) {
            hideOverlayMenu();
        }

        EventBus.emit('reset-active-index');
        unblockBodyScroll();
    }

    if (!profileClick.value && layoutMode.value !== 'static') {
        profileExpanded.value = false;
    }

    if (configActive.value && !configClick.value) {
        configActive.value = false;
    }

    topbarItemClick.value = false;
    menuClick.value = false;
    profileClick.value = false;
    configClick.value = false;
};

const onMenuButtonClick = (event: any) => {
    menuClick.value = true;
    topbarMenuActive.value = false;

    if (layoutMode.value === 'overlay') {
        overlayMenuActive.value = !overlayMenuActive.value;
    }

    if (isDesktop())
        staticMenuDesktopInactive.value = !staticMenuDesktopInactive.value;
    else {
        staticMenuMobileActive.value = !staticMenuMobileActive.value;
        if (staticMenuMobileActive.value) {
            blockBodyScroll();
        } else {
            unblockBodyScroll();
        }
    }

    event.preventDefault();
};

const onTopbarMenuButtonClick = (event: any) => {
    topbarItemClick.value = true;
    topbarMenuActive.value = !topbarMenuActive.value;
    hideOverlayMenu();
    event.preventDefault();
};

const onTopbarItemClick = (event: any) => {
    topbarItemClick.value = true;

    if (activeTopbarItem.value === event.item)
        activeTopbarItem.value = null;
    else
        activeTopbarItem.value = event.item;

    event.originalEvent.preventDefault();
};

const onMenuClick = () => {
    menuClick.value = true;
};

const isMenuVisible = () => {
    if (isDesktop()) {
        if (layoutMode.value === 'static')
            return !staticMenuDesktopInactive.value;
        else if (layoutMode.value === 'overlay')
            return overlayMenuActive.value;
        else
            return true;
    } else {
        return true;
    }
};

const onProfileClick = (event: any) => {
    profileClick.value = true;
    profileExpanded.value = !profileExpanded.value;
    if (isHorizontal() || isSlim()) {
        EventBus.emit('reset-active-index');
    }

    event.preventDefault();
};

const isHorizontal = () => {
    return layoutMode.value === 'horizontal';
};

const isSlim = () => {
    return layoutMode.value === 'slim';
};

const hideOverlayMenu = () => {
    overlayMenuActive.value = false;
    staticMenuMobileActive.value = false;
};

const isDesktop = () => {
    return window.innerWidth > 1024;
};

// eslint-disable-next-line no-unused-vars
const isMobile = () => {
    return window.innerWidth <= 640;
};

const onMenuItemClick = (event: any) => {
    if (!event.item.items) {
        EventBus.emit('reset-active-index');
        hideOverlayMenu();
    }
    if (!event.item.items && (isHorizontal() || isSlim())) {
        menuActive.value = false;
    }
};

const onRootMenuItemClick = () => {
    menuActive.value = !menuActive.value;
};

const addClass = (element: any, className: string) => {
    if (element.classList)
        element.classList.add(className);
    else
        element.className += ' ' + className;
};

const removeClass = (element: any, className: string) => {
    if (element.classList)
        element.classList.remove(className);
    else
        element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
};

const blockBodyScroll = () => {
    addClass(document.body, 'blocked-scroll');
};

const unblockBodyScroll = () => {
    removeClass(document.body, 'blocked-scroll');
};

watch(router.currentRoute, () => {
    menuActive.value = false;
    toast.removeAllGroups();
});

const closeConfirmation = () => {
    displayLogoutDialog.value = false;
};

const {
    auth,
    logOut,
} = inject<Store>('store', {});

const logOutLocal = () => {
    if (typeof logOut === 'function') {
        logOut();

        if(license.id == 60){
            // Para la redirección sin dejar historial en el navegador
            router.replace('/login/realplaza').catch(err => {
                // Manejar errores, por ejemplo, rutas no encontradas o problemas de redirección
                console.error(err);
            });
        } else {
            // Uso de router.replace para evitar el retorno al historial anterior
            router.replace('/login').catch(err => {
                // Manejar errores
                console.error(err);
            });
        }
    }
};

const onIdle = () => {
    logOutLocal();
    displayLogoutDialog.value = true;
};

</script>

<style lang='scss'>
@use 'App.scss';
</style>
