import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import ERouteType from '@/router/ERouteType';
import Storage from '@/data/global';
import ERolesType from '@/data/entity/enums/ERolesType';

const accessTypeId = Storage.auth.accessTypeId === undefined ? 0 : Storage.auth.accessTypeId;

const childHome = [
    // {
    //     path: '/',
    //     name: ERouteType.HOME_NAME,
    //     exact: true,
    //     component: () => import('../views/Home.vue'),
    //     meta: {
    //         breadcrumb: [{ label: 'Home' ,to: ERouteType.HOME_PATH } ],
    //     },
    // },
    // {
    //     path: '/dashboard',
    //     name: 'dashboard',
    //     exact: true,
    //     component: () => import('../components/Dashboard.vue'),
    //     meta: {
    //         breadcrumb: [{ label: 'Dashboard' }],
    //     },
    // },
    // {
    //     path: '/login',
    //     name: 'Login',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import(/* webpackChunkName: "about" */ '../pages/Login.vue'),
    //     meta: {
    //         breadcrumb: [{ label: 'Login' }],
    //     },
    // },
    // region Administrar
    {
        path: ERouteType.LICENSE_PATH,
        name: ERouteType.LICENSE_NAME,
        exact: true,
        component: () => import('../views/License.vue'),
        meta: {
            breadcrumb: [{ parent: 'Administrar', label: 'Agregar Licencia', to: ERouteType.LICENSE_PATH }],
        },
    },
    {
        path: ERouteType.LICENSE_LIST_PATH,
        name: ERouteType.LICENSE_LIST_NAME,
        exact: true,
        component: () => import('../views/LicenseList.vue'),
        meta: {
            breadcrumb: [{ parent: 'Administrar', label: 'Listado de Licencias', to: ERouteType.LICENSE_LIST_PATH }],
        },
    },
    {
        path: ERouteType.LICENSE_EDIT_PATH,
        name: ERouteType.LICENSE_EDIT_NAME,
        exact: true,
        component: () => import('../views/License.vue'),
        props: true,
        meta: {
            breadcrumb: [{ parent: 'Administrar', label: 'Editar Licencias', to: ERouteType.LICENSE_EDIT_PATH }],
        },
    },
    {
        path: ERouteType.USER_PATH,
        name: ERouteType.USER_NAME,
        exact: true,
        component: () => import('../views/User.vue'),
        props: true,
        meta: {
            breadcrumb: [{ parent: 'Administrar', label: 'Agregar Usuario', to: ERouteType.USER_PATH }],
        },
    },
    {
        path: ERouteType.USER_LIST_BY_LICENSE_PATH,
        name: ERouteType.USER_LIST_BY_LICENSE_NAME,
        exact: true,
        component: () => import('../views/UserList.vue'),
        props: true,
        meta: {
            breadcrumb: [{
                parent: 'Administrar',
                label: 'Listado de Usuarios',
                to: ERouteType.USER_LIST_BY_LICENSE_PATH,
            }],
        },
    },
    {
        path: ERouteType.USER_LIST_PATH,
        name: ERouteType.USER_LIST_NAME,
        exact: true,
        component: () => import('../views/UserList.vue'),
        props: true,
        meta: {
            breadcrumb: [{ parent: 'Administrar', label: 'Listado de Usuarios', to: ERouteType.USER_LIST_PATH }],
        },
    },
    {
        path: ERouteType.USER_EDIT_PATH,
        name: ERouteType.USER_EDIT_NAME,
        exact: true,
        component: () => import('../views/User.vue'),
        props: true,
        meta: {
            breadcrumb: [{ parent: 'Administrar', label: 'Editar Usuario', to: ERouteType.USER_EDIT_PATH }],
        },
    },
    {
        path: ERouteType.EXECUTOR_LIST_PATH,
        name: ERouteType.EXECUTOR_LIST_NAME,
        exact: true,
        component: () => import('../views/ExecutorList.vue'),
        props: true,
        meta: {
            breadcrumb: [{ parent: 'Administrar', label: 'Lista De Responsables', to: ERouteType.LICENSE_EDIT_PATH }],
        },
    },
    {
        path: ERouteType.CAMPUS_PROJECT_LIST_PATH,
        name: ERouteType.CAMPUS_PROJECT_LIST_NAME,
        exact: true,
        component: () => import('../views/CampusProjectList.vue'),
        props: true,
        meta: {
            breadcrumb: [{
                parent: 'Administrar',
                label: 'Lista De Sedes o Proyectos',
                to: ERouteType.CAMPUS_PROJECT_LIST_PATH,
            }],
        },
    },
    {
        path: ERouteType.ATTACHED_ENTITY_LIST_PATH,
        name: ERouteType.ATTACHED_ENTITY_LIST_NAME,
        exact: true,
        component: () => import('../views/AttachedEntityList.vue'),
        props: true,
        meta: {
            breadcrumb: [{
                parent: 'Administrar',
                label: 'Lista De Entidades Adscritas',
                to: ERouteType.ATTACHED_ENTITY_LIST_PATH,
            }],
        },
    },
    {
        path: ERouteType.ISSUING_ENTITY_LIST_PATH,
        name: ERouteType.ISSUING_ENTITY_LIST_NAME,
        exact: true,
        component: () => import('../views/IssuingEntityList.vue'),
        props: true,
        meta: {
            breadcrumb: [{
                parent: 'Administrar',
                label: 'Lista De Entidades Emisoras',
                to: ERouteType.ISSUING_ENTITY_LIST_PATH,
            }],
        },
    },
    {
        path: ERouteType.REGULARITY_ENTITY_LIST_PATH,
        name: ERouteType.REGULARITY_ENTITY_LIST_NAME,
        exact: true,
        component: () => import('../views/RegularityEntityList.vue'),
        props: true,
        meta: {
            breadcrumb: [{
                parent: 'Administrar',
                label: 'Lista De Entidades Reguladoras',
                to: ERouteType.REGULARITY_ENTITY_LIST_PATH,
            }],
        },
    },
    {
        path: ERouteType.LEGAL_RULE_TYPE_LIST_PATH,
        name: ERouteType.LEGAL_RULE_TYPE_LIST_NAME,
        exact: true,
        component: () => import('../views/LegalRuleTypeList.vue'),
        props: true,
        meta: {
            breadcrumb: [{
                parent: 'Administrar',
                label: 'Lista De Tipos de Norma',
                to: ERouteType.LEGAL_RULE_TYPE_LIST_PATH,
            }],
        },
    },
    {
        path: ERouteType.THEME_LIST_PATH,
        name: ERouteType.THEME_LIST_NAME,
        exact: true,
        component: () => import('../views/ThemeList.vue'),
        props: true,
        meta: {
            breadcrumb: [{ parent: 'Administrar', label: 'Lista De Temas y SubTemas', to: ERouteType.THEME_LIST_PATH }],
        },
    },
    {
        path: ERouteType.KEYWORD_LIST_PATH,
        name: ERouteType.KEYWORD_LIST_NAME,
        exact: true,
        component: () => import('../views/KeywordList.vue'),
        props: true,
        meta: {
            breadcrumb: [{ parent: 'Administrar', label: 'Lista De Palabras Clave', to: ERouteType.KEYWORD_LIST_PATH }],
        },
    },
    {
        path: ERouteType.LEGAL_MATTER_LIST_PATH,
        name: ERouteType.LEGAL_MATTER_LIST_NAME,
        exact: true,
        component: () => import('../views/LegalMatterList.vue'),
        props: true,
        meta: {
            breadcrumb: [{ parent: 'Administrar', label: 'Lista De Materias', to: ERouteType.LEGAL_MATTER_LIST_PATH }],
        },
    },
    {
        path: ERouteType.ACTIVITY_LIST_PATH,
        name: ERouteType.ACTIVITY_LIST_NAME,
        exact: true,
        component: () => import('../views/ActivityList.vue'),
        props: true,
        meta: {
            breadcrumb: [{ parent: 'Administrar', label: 'Lista De Actividades', to: ERouteType.ACTIVITY_LIST_PATH }],
        },
    },
    {
        path: ERouteType.THEME_BY_LEGAL_RULE_PATH,
        name: ERouteType.THEME_BY_LEGAL_RULE_NAME,
        exact: true,
        component: () => import('../views/ThemesByLegalRule.vue'),
        props: true,
        meta: {
            breadcrumb: [{ parent: 'Administrar', label: 'Temas por Norma', to: ERouteType.THEME_BY_LEGAL_RULE_PATH }],
        },
    },
    //endregion Administrar

    // region Gestionar
    {
        path: ERouteType.LEGAL_RULE_PATH,
        name: ERouteType.LEGAL_RULE_NAME,
        exact: true,
        component: () => import('../views/LegalRule.vue'),
        props: true,
        meta: {
            breadcrumb: [{ parent: 'Gestionar', label: 'Agregar Norma', to: ERouteType.LEGAL_RULE_PATH }],
        },
    },
    {
        path: ERouteType.LEGAL_RULE_EDIT_PATH,
        name: ERouteType.LEGAL_RULE_EDIT_NAME,
        exact: true,
        component: () => import('../views/LegalRule.vue'),
        props: true,
        meta: {
            breadcrumb: [{ parent: 'Gestionar', label: 'Editar Norma', to: ERouteType.LEGAL_RULE_EDIT_PATH }],
        },
    },
    {
        path: ERouteType.LEGAL_RULE_LIST_PATH,
        name: ERouteType.LEGAL_RULE_LIST_NAME,
        exact: true,
        component: () => import('../views/LegalRuleList.vue'),
        props: true,
        meta: {
            breadcrumb: [{ parent: 'Gestionar', label: 'Listado de Normas', to: ERouteType.LEGAL_RULE_LIST_PATH }],
        },
    },
    {
        path: ERouteType.IMPORT_LEGAL_RULES_PATH,
        name: ERouteType.IMPORT_LEGAL_RULES_NAME,
        exact: true,
        component: () => import('../views/ImportLegalRules.vue'),
        props: true,
        meta: {
            breadcrumb: [{ parent: 'Gestionar', label: 'Importar Normas', to: ERouteType.IMPORT_LEGAL_RULES_PATH }],
        },
    },
    {
        path: ERouteType.ARTICLE_PATH,
        name: ERouteType.ARTICLE_NAME,
        exact: true,
        component: () => import('../views/Article.vue'),
        props: true,
        meta: {
            breadcrumb: [{ parent: 'Gestionar', label: 'Agregar Artículo', to: ERouteType.ARTICLE_PATH }],
        },
    },
    {
        path: ERouteType.ARTICLE_LIST_PATH,
        name: ERouteType.ARTICLE_LIST_NAME,
        exact: true,
        component: () => import('../views/ArticleList.vue'),
        props: true,
        meta: {
            breadcrumb: [{ parent: 'Gestionar', label: 'Listado de Artículos', to: ERouteType.ARTICLE_LIST_PATH }],
        },
    },
    {
        path: ERouteType.ARTICLE_LIST_BY_LEGAL_RULE_PATH,
        name: ERouteType.ARTICLE_LIST_BY_LEGAL_RULE_NAME,
        exact: true,
        component: () => import('../views/ArticleList.vue'),
        props: true,
        meta: {
            breadcrumb: [{
                parent: 'Gestionar',
                label: 'Listado de Artículos',
                to: ERouteType.ARTICLE_LIST_BY_LEGAL_RULE_PATH,
            }],
        },
    },
    {
        path: ERouteType.ARTICLE_EDIT_PATH,
        name: ERouteType.ARTICLE_EDIT_NAME,
        exact: true,
        component: () => import('../views/Article.vue'),
        props: true,
        meta: {
            breadcrumb: [{ parent: 'Gestionar', label: 'Editar Artículo', to: ERouteType.ARTICLE_EDIT_PATH }],
        },
    },
    {
        path: ERouteType.MODIFICATION_LIST_PATH,
        name: ERouteType.MODIFICATION_LIST_NAME,
        exact: true,
        component: () => import('../views/ModificationList.vue'),
        props: true,
        meta: {
            breadcrumb: [{
                parent: 'Gestionar',
                label: 'Listado de Modificatorias',
                to: ERouteType.MODIFICATION_LIST_PATH,
            }],
        },
    },
    {
        path: ERouteType.MODIFICATION_PATH,
        name: ERouteType.MODIFICATION_NAME,
        exact: true,
        component: () => import('../views/Modification.vue'),
        props: true,
        meta: {
            breadcrumb: [{ parent: 'Gestionar', label: 'Agregar Modificatoria', to: ERouteType.MODIFICATION_PATH }],
        },
    },
    {
        path: ERouteType.MODIFICATION_EDIT_PATH,
        name: ERouteType.MODIFICATION_EDIT_NAME,
        exact: true,
        component: () => import('../views/Modification.vue'),
        props: true,
        meta: {
            breadcrumb: [{ parent: 'Gestionar', label: 'Editar Modificatoria', to: ERouteType.MODIFICATION_EDIT_PATH }],
        },
    },
    {
        path: ERouteType.ABROGATE_LIST_PATH,
        name: ERouteType.ABROGATE_LIST_NAME,
        exact: true,
        component: () => import('../views/AbrogateList.vue'),
        props: true,
        meta: {
            breadcrumb: [{ parent: 'Gestionar', label: 'Listado de Derogatorias', to: ERouteType.ABROGATE_LIST_PATH }],
        },
    },
    {
        path: ERouteType.ABROGATE_EDIT_PATH,
        name: ERouteType.ABROGATE_EDIT_NAME,
        exact: true,
        component: () => import('../views/Abrogate.vue'),
        props: true,
        meta: {
            breadcrumb: [{ parent: 'Gestionar', label: 'Editar Derogatoria', to: ERouteType.ABROGATE_EDIT_PATH }],
        },
    },
    {
        path: ERouteType.ABROGATE_PATH,
        name: ERouteType.ABROGATE_NAME,
        exact: true,
        component: () => import('../views/Abrogate.vue'),
        props: true,
        meta: {
            breadcrumb: [{ parent: 'Gestionar', label: 'Agregar Derogatoria', to: ERouteType.ABROGATE_PATH }],
        },
    },
    // {
    //     path: ERouteType.ERRATA_LIST_PATH,
    //     name: ERouteType.ERRATA_LIST_NAME,
    //     exact: true,
    //     component: () => import('../views/Modification.vue'),
    //     props: true,
    //     meta: {
    //         breadcrumb: [{ parent: 'Gestionar', label: 'Agregar Derogatoria', to: ERouteType.ERRATA_LIST_PATH }],
    //     },
    // },
    {
        path: ERouteType.ERRATA_EDIT_PATH,
        name: ERouteType.ERRATA_EDIT_NAME,
        exact: true,
        component: () => import('../views/Errata.vue'),
        props: true,
        meta: {
            breadcrumb: [{ parent: 'Gestionar', label: 'Editar Errata', to: ERouteType.ERRATA_EDIT_PATH }],
        },
    },
    {
        path: ERouteType.ERRATA_PATH,
        name: ERouteType.ERRATA_NAME,
        exact: true,
        component: () => import('../views/Errata.vue'),
        props: true,
        meta: {
            breadcrumb: [{ parent: 'Gestionar', label: 'Agregar Errata', to: ERouteType.ERRATA_PATH }],
        },
    },
    {
        path: ERouteType.IMPORT_LEGAL_RULES_SUBSCRIPTION_PATH,
        name: ERouteType.IMPORT_LEGAL_RULES_SUBSCRIPTION_NAME,
        exact: true,
        component: () => import('../views/ImportLegalRulesSubscription.vue'),
        props: true,
        meta: {
            breadcrumb: [{
                parent: 'Gestionar',
                label: 'Importar Normas para Suscriptores',
                to: ERouteType.IMPORT_LEGAL_RULES_SUBSCRIPTION_PATH,
            }],
        },
    },
    {
        path: ERouteType.LEGAL_RULE_SUBSCRIPTION_LIST_PATH,
        name: ERouteType.LEGAL_RULE_SUBSCRIPTION_LIST_NAME,
        exact: true,
        component: () => import('../views/LegalRuleSubscriptionList.vue'),
        props: true,
        meta: {
            breadcrumb: [{
                parent: 'Gestionar',
                label: 'Listado de Normas para Suscriptores',
                to: ERouteType.LEGAL_RULE_SUBSCRIPTION_LIST_PATH,
            }],
        },
    },
    {
        path: ERouteType.LEGAL_RULE_LIST_BY_CRITERIA_PATH,
        name: ERouteType.LEGAL_RULE_LIST_BY_CRITERIA_NAME,
        exact: true,
        component: () => import('../views/LegalRuleListByCriteria.vue'),
        props: true,
        meta: {
            breadcrumb: [{
                parent: 'Gestionar',
                label: 'Listado de Normas',
                to: ERouteType.LEGAL_RULE_LIST_BY_CRITERIA_PATH,
            }],
        },
    },
    {
        path: ERouteType.ARTICLE_LIST_BY_CRITERIA_PATH,
        name: ERouteType.ARTICLE_LIST_BY_CRITERIA_NAME,
        exact: true,
        component: () => import('../views/ArticleListByCriteria.vue'),
        props: true,
        meta: {
            breadcrumb: [{
                parent: 'Gestionar',
                label: 'Listado de Artículos',
                to: ERouteType.ARTICLE_LIST_BY_CRITERIA_PATH,
            }],
        },
    },
    {
        path: ERouteType.LEGAL_MATRIX_LIST_PATH,
        name: ERouteType.LEGAL_MATRIX_LIST_NAME,
        exact: true,
        component: () => import('../views/LegalMatrixList.vue'),
        props: true,
        meta: {
            breadcrumb: [{ parent: 'Gestionar', label: 'Matrices de evaluación', to: ERouteType.LEGAL_MATRIX_LIST_PATH }],
        },
    },
    {
        path: ERouteType.LEGAL_MATRIX_PATH,
        name: ERouteType.LEGAL_MATRIX_NAME,
        exact: true,
        component: () => import('../views/LegalMatrix.vue'),
        props: true,
        meta: {
            breadcrumb: [{ parent: 'Gestionar', label: 'Agregar Matriz de evaluación', to: ERouteType.LEGAL_MATRIX_PATH }],
        },
    },
    {
        path: ERouteType.LEGAL_MATRIX_EDIT_PATH,
        name: ERouteType.LEGAL_MATRIX_EDIT_NAME,
        exact: true,
        component: () => import('../views/LegalMatrix.vue'),
        props: true,
        meta: {
            breadcrumb: [{ parent: 'Gestionar', label: 'Editar Matriz de evaluación', to: ERouteType.LEGAL_MATRIX_EDIT_PATH }],
        },
    },
    {
        path: ERouteType.ARTICLE_LIST_BY_MATRIX_PATH,
        name: ERouteType.ARTICLE_LIST_BY_MATRIX_NAME,
        exact: true,
        component: () => import('../views/ArticleListByMatrix.vue'),
        props: true,
        meta: {
            breadcrumb: [{
                parent: 'Gestionar',
                label: 'Artículos de Matriz de evaluación',
                to: ERouteType.ARTICLE_LIST_BY_MATRIX_PATH,
            }],
        },
    },
    {
        path: ERouteType.ARTICLES_BY_MATRIX_PATH,
        name: ERouteType.ARTICLES_BY_MATRIX_NAME,
        exact: true,
        component: () => import('../views/ArticlesByMatrix.vue'),
        props: true,
        meta: {
            breadcrumb: [{ parent: 'Gestionar', label: 'Artículos de Matriz de evaluación', to: ERouteType.ARTICLES_BY_MATRIX_PATH }],
        },
    },
    {
        path: ERouteType.ACTION_PLAN_PATH,
        name: ERouteType.ACTION_PLAN_NAME,
        exact: true,
        component: () => import('../views/ActionPlan.vue'),
        props: true,
        meta: {
            breadcrumb: [{ parent: 'Gestionar', label: 'Agregar Plan de Acción', to: ERouteType.ACTION_PLAN_PATH }],
        },
    },
    {
        path: ERouteType.ACTION_PLAN_EDIT_PATH,
        name: ERouteType.ACTION_PLAN_EDIT_NAME,
        exact: true,
        component: () => import('../views/ActionPlan.vue'),
        props: true,
        meta: {
            breadcrumb: [{ parent: 'Gestionar', label: 'Editar Plan de Acción', to: ERouteType.ACTION_PLAN_EDIT_PATH }],
        },
    },
    {
        path: ERouteType.EVALUATION_LAST_LIST_PATH,
        name: ERouteType.EVALUATION_LAST_LIST_NAME,
        exact: true,
        component: () => import('../views/EvaluationLastList.vue'),
        props: true,
        meta: {
            breadcrumb: [{
                parent: 'Gestionar',
                label: 'Ultimas evaluaciones',
                to: ERouteType.EVALUATION_LAST_LIST_PATH,
            }],
        },
    },
    {
        path: ERouteType.ACTION_PLAN_LIST_PATH,
        name: ERouteType.ACTION_PLAN_LIST_NAME,
        exact: true,
        component: () => import('../views/ActionPlanListByLicense.vue'),
        props: true,
        meta: {
            breadcrumb: [{ parent: 'Gestionar', label: 'Planes de Acción', to: ERouteType.ACTION_PLAN_LIST_PATH }],
        },
    },
    {
        path: ERouteType.EVALUATION_PATH,
        name: ERouteType.EVALUATION_NAME,
        exact: true,
        component: () => import('../views/Evaluation.vue'),
        props: true,
        meta: {
            breadcrumb: [{ parent: 'Gestionar', label: 'Agregar Evaluación', to: ERouteType.EVALUATION_PATH }],
        },
    },
    {
        path: ERouteType.EVALUATION_EDIT_PATH,
        name: ERouteType.EVALUATION_EDIT_NAME,
        exact: true,
        component: () => import('../views/Evaluation.vue'),
        props: true,
        meta: {
            breadcrumb: [{ parent: 'Gestionar', label: 'Editar Evaluación', to: ERouteType.EVALUATION_EDIT_PATH }],
        },
    },
    {
        path: ERouteType.EXPORT_MATRIX_TO_EXCEL_PATH,
        name: ERouteType.EXPORT_MATRIX_TO_EXCEL_NAME,
        exact: true,
        component: () => import('../views/ExportMatrixToExcel.vue'),
        props: true,
        meta: {
            breadcrumb: [{
                parent: 'Gestionar',
                label: 'Exportar Artículos de Matriz de evaluación',
                to: ERouteType.EXPORT_MATRIX_TO_EXCEL_PATH,
            }],
        },
    },
    {
        path: ERouteType.EXPORT_MATRIX_TO_EXCEL_BY_EVALUATION_COMPLIANCE_PATH,
        name: ERouteType.EXPORT_MATRIX_TO_EXCEL_BY_EVALUATION_COMPLIANCE_NAME,
        exact: true,
        component: () => import('../views/ExportMatrixToExcelByEvaluationCompliance.vue'),
        props: true,
        meta: {
            breadcrumb: [{
                parent: 'Gestionar',
                label: 'Exportar Matriz de Evaluación de Cumplimiento',
                to: ERouteType.EXPORT_MATRIX_TO_EXCEL_BY_EVALUATION_COMPLIANCE_PATH,
            }],
        },
    },
    //endregion Gestionar

    //region Reportes
    {
        path: ERouteType.COMPLIANCE_SUMMARY_BY_CRITERIA_PATH,
        name: ERouteType.COMPLIANCE_SUMMARY_BY_CRITERIA_NAME,
        exact: true,
        component: () => import('../views/ComplianceSummaryByCriteria.vue'),
        props: true,
        meta: {
            breadcrumb: [{
                parent: 'Reportes',
                label: 'Cumplimiento de Normas: General',
                to: ERouteType.COMPLIANCE_SUMMARY_BY_CRITERIA_PATH,
            }],
        },
    },
    {
        path: ERouteType.COMPLIANCE_GENERAL_UNIQUE_PATH,
        name: ERouteType.COMPLIANCE_GENERAL_UNIQUE_NAME,
        exact: true,
        component: () => import('../views/ComplianceGeneralUnique.vue'),
        props: true,
        meta: {
            breadcrumb: [{
                parent: 'Reportes',
                label: 'Cumplimiento de Normas: Gerencial',
                to: ERouteType.COMPLIANCE_GENERAL_UNIQUE_PATH,
            }],
        },
    },
    {
        path: ERouteType.COMPLIANCE_GENERAL_MULTIPLE_PATH,
        name: ERouteType.COMPLIANCE_GENERAL_MULTIPLE_NAME,
        exact: true,
        component: () => import('../views/ComplianceGeneralMultiple.vue'),
        props: true,
        meta: {
            breadcrumb: [{
                parent: 'Reportes',
                label: 'Cumplimiento de Normas: Gerencial (múltiples sedes o proyectos)',
                to: ERouteType.COMPLIANCE_GENERAL_MULTIPLE_PATH,
            }],
        },
    },
    {
        path: ERouteType.COMPLIANCE_SUMMARY_BY_CRITERIA_PBI_PATH,
        name: ERouteType.COMPLIANCE_SUMMARY_BY_CRITERIA_PBI_NAME,
        exact: true,
        component: () => import('../views/ComplianceSummaryByCriteriaPbi.vue'),
        props: true,
        meta: {
            breadcrumb: [{
                parent: 'Reportes',
                label: 'Cumplimiento de Normas: General (BI)',
                to: ERouteType.COMPLIANCE_SUMMARY_BY_CRITERIA_PBI_PATH,
            }],
        },
    },
    {
        path: ERouteType.COMPLIANCE_GENERAL_PBI_PATH,
        name: ERouteType.COMPLIANCE_GENERAL_PBI_NAME,
        exact: true,
        component: () => import('../views/ComplianceGeneralPbi.vue'),
        props: true,
        meta: {
            breadcrumb: [{
                parent: 'Reportes',
                label: 'Cumplimiento de Normas: Gerencial (BI)',
                to: ERouteType.COMPLIANCE_GENERAL_PBI_PATH,
            }],
        },
    },
    {
        path: ERouteType.COMPLIANCE_GENERAL_MULTIPLE_PBI_PATH,
        name: ERouteType.COMPLIANCE_GENERAL_MULTIPLE_PBI_NAME,
        exact: true,
        component: () => import('../views/ComplianceGeneralMultiplePbi.vue'),
        props: true,
        meta: {
            breadcrumb: [{
                parent: 'Reportes',
                label: 'Reporte de cumplimiento: General para sedes o proyectos',
                to: ERouteType.COMPLIANCE_GENERAL_MULTIPLE_PBI_PATH,
            }],
        },
    },
    //endregion Reportes
    {
        path: ERouteType.LICENSE_BY_USER_PATH,
        name: ERouteType.LICENSE_BY_USER_NAME,
        exact: true,
        component: () => import('../views/LicenseByUser.vue'),
        props: true,
        meta: {
            breadcrumb: [{ label: 'Mi Licencia', to: ERouteType.LICENSE_BY_USER_PATH }],
        },
    },

    // {
    //     path: '/login',
    //     name: 'Login',
    //     exact: true,
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import(/* webpackChunkName: "about" */ '../pages/Login.vue'),
    //     meta: {
    //         breadcrumb: [{ label: 'Login' }],
    //     }
    // },

];

const fnChildHome = (x: any) => {
    let valid = false;

    if (x.path === ERouteType.LICENSE_PATH && accessTypeId === ERolesType.SUPER_USER) {
        valid = true;
    }

    if (x.path === ERouteType.LICENSE_LIST_PATH && accessTypeId === ERolesType.SUPER_USER) {
        valid = true;
    }

    if (x.path === ERouteType.LICENSE_EDIT_PATH && accessTypeId === ERolesType.SUPER_USER) {
        valid = true;
    }

    if (x.path === ERouteType.USER_PATH && (accessTypeId === ERolesType.SUPER_USER || accessTypeId === ERolesType.ADMIN)) {
        valid = true;
    }

    if (x.path === ERouteType.USER_LIST_BY_LICENSE_PATH && (accessTypeId === ERolesType.SUPER_USER || accessTypeId === ERolesType.ADMIN)) {
        valid = true;
    }

    if (x.path === ERouteType.USER_LIST_PATH && (accessTypeId === ERolesType.SUPER_USER || accessTypeId === ERolesType.ADMIN)) {
        valid = true;
    }

    if (x.path === ERouteType.USER_EDIT_PATH && (accessTypeId === ERolesType.SUPER_USER || accessTypeId === ERolesType.ADMIN)) {
        valid = true;
    }

    if (x.path === ERouteType.LEGAL_RULE_PATH && accessTypeId === ERolesType.LAWYER) {
        valid = true;
    }

    if (x.path === ERouteType.LEGAL_RULE_LIST_PATH && accessTypeId === ERolesType.LAWYER) {
        valid = true;
    }

    if (x.path === ERouteType.LEGAL_RULE_EDIT_PATH && accessTypeId === ERolesType.LAWYER) {
        valid = true;
    }

    if (x.path === ERouteType.KEYWORD_PATH && accessTypeId === ERolesType.LAWYER) {
        valid = true;
    }

    if (x.path === ERouteType.KEYWORD_LIST_PATH && accessTypeId === ERolesType.LAWYER) {
        valid = true;
    }

    if (x.path === ERouteType.KEYWORD_EDIT_PATH && accessTypeId === ERolesType.LAWYER) {
        valid = true;
    }

    if (x.path === ERouteType.LEGAL_MATTER_PATH && accessTypeId === ERolesType.LAWYER) {
        valid = true;
    }

    if (x.path === ERouteType.LEGAL_MATTER_LIST_PATH && accessTypeId === ERolesType.LAWYER) {
        valid = true;
    }

    if (x.path === ERouteType.LEGAL_MATTER_EDIT_PATH && accessTypeId === ERolesType.LAWYER) {
        valid = true;
    }

    if (x.path === ERouteType.ACTIVITY_PATH && accessTypeId === ERolesType.LAWYER) {
        valid = true;
    }

    if (x.path === ERouteType.ACTIVITY_LIST_PATH && accessTypeId === ERolesType.LAWYER) {
        valid = true;
    }

    if (x.path === ERouteType.ACTIVITY_EDIT_PATH && accessTypeId === ERolesType.LAWYER) {
        valid = true;
    }

    if (x.path === ERouteType.LEGAL_RULE_TYPE_PATH && accessTypeId === ERolesType.LAWYER) {
        valid = true;
    }

    if (x.path === ERouteType.LEGAL_RULE_TYPE_LIST_PATH && accessTypeId === ERolesType.LAWYER) {
        valid = true;
    }

    if (x.path === ERouteType.LEGAL_RULE_TYPE_EDIT_PATH && accessTypeId === ERolesType.LAWYER) {
        valid = true;
    }

    if (x.path === ERouteType.ARTICLE_PATH && accessTypeId === ERolesType.LAWYER) {
        valid = true;
    }

    if (x.path === ERouteType.ARTICLE_LIST_PATH && accessTypeId === ERolesType.LAWYER) {
        valid = true;
    }

    if (x.path === ERouteType.ARTICLE_LIST_BY_LEGAL_RULE_PATH && (accessTypeId === ERolesType.ADMIN
        || accessTypeId === ERolesType.USER || accessTypeId === ERolesType.LAWYER)) {
        valid = true;
    }

    if (x.path === ERouteType.ARTICLE_EDIT_PATH && accessTypeId === ERolesType.LAWYER) {
        valid = true;
    }

    if (x.path === ERouteType.MODIFICATION_LIST_PATH && accessTypeId === ERolesType.LAWYER) {
        valid = true;
    }

    if (x.path === ERouteType.MODIFICATION_EDIT_PATH && accessTypeId === ERolesType.LAWYER) {
        valid = true;
    }

    if (x.path === ERouteType.MODIFICATION_PATH && accessTypeId === ERolesType.LAWYER) {
        valid = true;
    }

    if (x.path === ERouteType.ERRATA_EDIT_PATH && accessTypeId === ERolesType.LAWYER) {
        valid = true;
    }

    if (x.path === ERouteType.ERRATA_PATH && accessTypeId === ERolesType.LAWYER) {
        valid = true;
    }

    if (x.path === ERouteType.ABROGATE_LIST_PATH && accessTypeId === ERolesType.LAWYER) {
        valid = true;
    }

    if (x.path === ERouteType.ABROGATE_EDIT_PATH && accessTypeId === ERolesType.LAWYER) {
        valid = true;
    }

    if (x.path === ERouteType.ABROGATE_PATH && accessTypeId === ERolesType.LAWYER) {
        valid = true;
    }

    if (x.path === ERouteType.CONFIGURATIONS_PATH && (accessTypeId === ERolesType.ADMIN || accessTypeId === ERolesType.SUPER_USER)) {
        valid = true;
    }

    if (x.path === ERouteType.OPTIONS_LEGAL_RULE_PATH && (accessTypeId === ERolesType.ADMIN || accessTypeId === ERolesType.USER)) {
        valid = true;
    }

    if (x.path === ERouteType.OPTIONS_LEGAL_RULE_TYPE_PATH && (accessTypeId === ERolesType.ADMIN || accessTypeId === ERolesType.USER)) {
        valid = true;
    }

    if (x.path === ERouteType.LEGAL_RULE_LIST_BY_CRITERIA_PATH && (accessTypeId === ERolesType.ADMIN || accessTypeId === ERolesType.USER)) {
        valid = true;
    }

    if (x.path === ERouteType.ARTICLE_LIST_BY_CRITERIA_PATH && (accessTypeId === ERolesType.ADMIN || accessTypeId === ERolesType.USER)) {
        valid = true;
    }

    if (x.path === ERouteType.THEME_BY_LEGAL_RULE_PATH && (accessTypeId === ERolesType.ADMIN || accessTypeId === ERolesType.USER)) {
        valid = true;
    }

    if (x.path === ERouteType.COMPLIANCE_SUMMARY_BY_CRITERIA_PATH && (accessTypeId === ERolesType.ADMIN
        || accessTypeId === ERolesType.USER)) {
        valid = true;
    }

    if (x.path === ERouteType.COMPLIANCE_GENERAL_UNIQUE_PATH && (accessTypeId === ERolesType.ADMIN
        || accessTypeId === ERolesType.USER)) {
        valid = true;
    }

    if (x.path === ERouteType.COMPLIANCE_GENERAL_MULTIPLE_PATH && (accessTypeId === ERolesType.ADMIN
        || accessTypeId === ERolesType.USER)) {
        valid = true;
    }

    if (x.path === ERouteType.COMPLIANCE_SUMMARY_BY_CRITERIA_PBI_PATH && (accessTypeId === ERolesType.ADMIN
        || accessTypeId === ERolesType.USER)) {
        valid = true;
    }

    if (x.path === ERouteType.COMPLIANCE_GENERAL_PBI_PATH && (accessTypeId === ERolesType.ADMIN
        || accessTypeId === ERolesType.USER)) {
        valid = true;
    }

    if (x.path === ERouteType.COMPLIANCE_GENERAL_MULTIPLE_PBI_PATH && (accessTypeId === ERolesType.ADMIN
        || accessTypeId === ERolesType.USER)) {
        valid = true;
    }

    if (x.path === ERouteType.LEGAL_MATRIX_PATH && (accessTypeId === ERolesType.ADMIN || accessTypeId === ERolesType.USER)) {
        valid = true;
    }

    if (x.path === ERouteType.LEGAL_MATRIX_LIST_PATH && (accessTypeId === ERolesType.ADMIN || accessTypeId === ERolesType.USER)) {
        valid = true;
    }

    if (x.path === ERouteType.LEGAL_MATRIX_EDIT_PATH && (accessTypeId === ERolesType.ADMIN || accessTypeId === ERolesType.USER)) {
        valid = true;
    }

    if (x.path === ERouteType.SELECTED_FILTER_PATH && (accessTypeId === ERolesType.ADMIN || accessTypeId === ERolesType.USER)) {
        valid = true;
    }

    if (x.path === ERouteType.ARTICLE_LIST_BY_MATRIX_PATH && (accessTypeId === ERolesType.ADMIN || accessTypeId === ERolesType.USER)) {
        valid = true;
    }

    if (x.path === ERouteType.ARTICLES_BY_MATRIX_PATH && (accessTypeId === ERolesType.ADMIN || accessTypeId === ERolesType.USER)) {
        valid = true;
    }

    if (x.path === ERouteType.EXPORT_MATRIX_TO_EXCEL_PATH && (accessTypeId === ERolesType.ADMIN || accessTypeId === ERolesType.USER)) {
        valid = true;
    }

    if (x.path === ERouteType.EXPORT_MATRIX_TO_EXCEL_BY_EVALUATION_COMPLIANCE_PATH && (accessTypeId === ERolesType.ADMIN || accessTypeId === ERolesType.USER)) {
        valid = true;
    }

    if (x.path === ERouteType.EVALUATION_PATH && (accessTypeId === ERolesType.ADMIN || accessTypeId === ERolesType.USER)) {
        valid = true;
    }

    if (x.path === ERouteType.EVALUATION_EDIT_PATH && (accessTypeId === ERolesType.ADMIN || accessTypeId === ERolesType.USER)) {
        valid = true;
    }

    if (x.path === ERouteType.EVALUATION_LAST_LIST_PATH && (accessTypeId === ERolesType.ADMIN || accessTypeId === ERolesType.USER)) {
        valid = true;
    }

    if (x.path === ERouteType.ACTION_PLAN_PATH && (accessTypeId === ERolesType.ADMIN || accessTypeId === ERolesType.USER)) {
        valid = true;
    }

    if (x.path === ERouteType.ACTION_PLAN_EDIT_PATH && (accessTypeId === ERolesType.ADMIN || accessTypeId === ERolesType.USER)) {
        valid = true;
    }

    if (x.path === ERouteType.ACTION_PLAN_LIST_PATH && (accessTypeId === ERolesType.ADMIN || accessTypeId === ERolesType.USER)) {
        valid = true;
    }

    if (x.path === ERouteType.LICENSE_BY_USER_PATH && (accessTypeId === ERolesType.ADMIN || accessTypeId === ERolesType.USER)) {
        valid = true;
    }

    if (x.path === ERouteType.EXECUTOR_LIST_PATH && (accessTypeId === ERolesType.ADMIN || accessTypeId === ERolesType.USER)) {
        valid = true;
    }

    if (x.path === ERouteType.CAMPUS_PROJECT_LIST_PATH && (accessTypeId === ERolesType.ADMIN || accessTypeId === ERolesType.USER)) {
        valid = true;
    }

    if (x.path === ERouteType.IMPORT_LEGAL_RULES_PATH && (accessTypeId === ERolesType.LAWYER || accessTypeId === ERolesType.SUPER_USER)) {
        valid = true;
    }

    if (x.path === ERouteType.IMPORT_LEGAL_RULES_SUBSCRIPTION_PATH && (accessTypeId === ERolesType.LAWYER
        || accessTypeId === ERolesType.SUPER_USER)) {
        valid = true;
    }

    if (x.path === ERouteType.OPTIONS_USERS_PATH && (accessTypeId === ERolesType.ADMIN || accessTypeId === ERolesType.USER)) {
        valid = true;
    }

    if (x.path === ERouteType.ISSUING_ENTITY_PATH && accessTypeId === ERolesType.LAWYER) {
        valid = true;
    }

    if (x.path === ERouteType.ATTACHED_ENTITY_LIST_PATH && accessTypeId === ERolesType.LAWYER) {
        valid = true;
    }

    if (x.path === ERouteType.ISSUING_ENTITY_LIST_PATH && accessTypeId === ERolesType.LAWYER) {
        valid = true;
    }

    if (x.path === ERouteType.ISSUING_ENTITY_EDIT_PATH && accessTypeId === ERolesType.LAWYER) {
        valid = true;
    }

    if (x.path === ERouteType.REGULARITY_ENTITY_LIST_PATH && accessTypeId === ERolesType.LAWYER) {
        valid = true;
    }

    if (x.path === ERouteType.THEME_PATH && accessTypeId === ERolesType.LAWYER) {
        valid = true;
    }

    if (x.path === ERouteType.THEME_LIST_PATH && accessTypeId === ERolesType.LAWYER) {
        valid = true;
    }

    if (x.path === ERouteType.THEME_EDIT_PATH && accessTypeId === ERolesType.LAWYER) {
        valid = true;
    }

    if (x.path === ERouteType.LEGAL_RULE_SUBSCRIPTION_LIST_PATH && accessTypeId === ERolesType.LAWYER) {
        valid = true;
    }

    return valid;
};

const filterChildHome = childHome.filter(fnChildHome);

const routes: Array<RouteRecordRaw> = [...childHome,
    {
        path: '/',
        name: ERouteType.HOME_NAME,
        component: () => import('../views/Home.vue'),
        meta: {
            breadcrumb: [{ label: 'Home', to: ERouteType.HOME_PATH }],
        },
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('../components/Dashboard.vue'),
        meta: {
            breadcrumb: [{ label: 'Dashboard' }],
        },
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "about" */ '../pages/Login.vue'),
        meta: {
            breadcrumb: [{ label: 'Login' }],
        },
    },
    {
        path: '/login/realplaza',
        name: 'LoginRealPlaza',
        component: () => import(/* webpackChunkName: "about" */ '../pages/LoginRealPlaza.vue'),
        meta: {
            breadcrumb: [{ label: 'Login Real Plaza' }],
        },
    },
    {
        path: '/subscription',
        name: 'Subscription',
        component: () => import(/* webpackChunkName: "about" */ '../views/Subscription.vue'),
        meta: {
            breadcrumb: [{ label: 'Subscription' }],
        },
    },
    {
        path: '/error',
        name: 'Error',
        component: () => import(/* webpackChunkName: "about" */ '../pages/Error.vue'),
        meta: {
            breadcrumb: [{ label: 'Error' }],
        },
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        component: () => import(/* webpackChunkName: "about" */ '../pages/NotFound.vue'),
        meta: {
            breadcrumb: [{ label: 'not-found' }],
        },
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { left: 0, top: 0 };
    },
});

export default router;